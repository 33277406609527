<template>
  <div>
    <ion-item>
      <avatar
        slot="start"
        :initials="initials"
        :value="item.logo_tn"
      />
      <ion-label>
        <h3 v-text="item.name"></h3>
        <p v-text="item.trainers.join(', ')"></p>
      </ion-label>
    </ion-item>
    <img
      v-if="item.stripe_products_slugs && item.stripe_products_slugs.some(i => ['ptic'].includes(i))"
      class="club-badge"
      src="@/assets/images/ptic.png"
      alt="ptic"
    >
  </div>
</template>

<script>
import Avatar from "./Avatar";

export default {
  name: "ClubItem",
  components: {Avatar},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    initials() {
      let initials = ''
      const name = this.item.name ?? ''

      name.split(' ').forEach(word => {
        initials += word.substring(0, 1)
      })

      return initials
    }
  }
}
</script>

<style lang="scss">
.club-badge {
  position: absolute;
  bottom: 8px;
  left: 40px;
  width: 18px;
  height: 18px;
  z-index: 1;
}
</style>