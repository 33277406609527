<template>
  <ion-header>
    <ion-toolbar>
      <slot/>
      <ion-buttons slot="end">
        <ion-button class="notification-button" router-link="/tabs/notifications">
          <ion-icon :icon="notificationsOutline" color="primary"></ion-icon>
          <ion-badge class="notifications-badge" v-if="notificationsCounter > 0" color="danger"
                     v-text="displayNotificationsCounter"></ion-badge>
        </ion-button>
        <ion-button class="notification-button" router-link="/tabs/my-profile">
          <ion-icon :icon="personOutline" color="primary"></ion-icon>
          <ion-badge class="notifications-badge" v-if="myProfileCounter > 0" color="danger"
                     v-text="myProfileCounter"></ion-badge>
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script>
import {IonIcon} from '@ionic/vue';
import {notificationsOutline, personOutline} from 'ionicons/icons';

export default {
  name: "HeaderToolbar",
  components: {IonIcon},
  setup() {
    return {notificationsOutline, personOutline}
  },
  computed: {
    notificationsCounter() {
      return this.$store.state.notificationsCounter ?? 0;
    },
    displayNotificationsCounter() {
      if (this.notificationsCounter > 9) {
        return '9+'
      }

      return this.notificationsCounter
    },
    myProfileCounter() {
      return this.$store.state.myProfileCounter ?? 0;
    },
  },
}
</script>

<style lang="scss">
.notification-button {
  position: relative;
}

.notifications-badge {
  position: absolute;
  top: -1px;
  right: -6px;
  border-radius: 10px;
  padding: 1px 6px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  transform: scale(0.8);
}
</style>