<template>
  <ion-page>
    <header-toolbar>
      <ion-title>{{ __('interface.clubs') }}</ion-title>
    </header-toolbar>
    <ion-content :fullscreen="true">
      <ion-refresher slot="fixed" pull-factor="0.5" pull-min="100" pull-max="200" @ionRefresh="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list-header v-text="__('interface.my-clubs')"></ion-list-header>
      <ion-list>
        <club-item v-for="(item) in clubs" :key="item.id" :item="item"
                   @click="$router.push('/tabs/clubs/' + item.id)"></club-item>
        <ion-item v-if="clubs.length <= 0">
          <ion-label v-text="__('interface.no-results')"></ion-label>
        </ion-item>
      </ion-list>
      <ion-list-header v-text="__('interface.invitations')"></ion-list-header>
      <club-item v-for="(item) in invitations" :key="item.id" :item="item" @click="actionInvitation(item)"></club-item>
      <ion-item v-if="invitations.length <= 0">
        <ion-label v-text="__('interface.no-results')"></ion-label>
      </ion-item>
      <ion-button expand="full" class="ion-margin-vertical" @click="actionInvite">
        {{ __('interface.invite-your-trainer') }}
      </ion-button>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  actionSheetController,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonListHeader,
  loadingController
} from '@ionic/vue'
import {API} from "@/utils/apiWrapper";
import HeaderToolbar from "../../layouts/HeaderToolbar";
import ClubItem from "../../components/ClubItem";

export default {
  name: "ClubIndex",
  components: {ClubItem, HeaderToolbar, IonPage, IonRefresherContent, IonRefresher, IonListHeader},
  data() {
    return {
      clubs: [],
      invitations: [],
    }
  },
  methods: {
    async actionInvite() {
      await this.$router.push({name: 'ClubInvite'})
    },
    doRefresh(event) {
      this.loadData().finally(() => {
        event.target.complete()
      })
    },
    loadData() {
      return API.get('TheSpottApi', '/clubs-customer', {}).then(response => {
        this.clubs = response.data.clubs
        this.invitations = response.data.invitations
      })
    },
    async doClientInviteAction(item, endpoint) {
      const loading = await loadingController.create({
        message: this.__('interface.loading'),
      });
      await loading.present();

      await API.post('TheSpottApi', endpoint, {
        body: {
          clubId: item.id
        }
      });

      await this.loadData()

      await API.get('TheSpottApi', '/notification-count', {}).then(response => {
        this.$store.commit('setInvitationsCounter', response.data.invitations.count ?? 0);
      })

      await loading.dismiss();
    },
    async actionClientInviteAccept(item) {
      await this.doClientInviteAction(item, '/client-invite-accept')
      this.$tracking.event({
          eventName: 'club-invitation-accepted',
          category: 'invitations',
          event: item
      })
    },
    async actionClientInviteDecline(item) {
      await this.doClientInviteAction(item, '/client-invite-decline')
      this.$tracking.event({
          eventName: 'club-invitation-rejected',
          category: 'invitations',
          event: item
      });
    },
    async actionInvitation(item) {
      const actionSheet = await actionSheetController.create({
        header: this.__('interface.options'),
        buttons: [
          {
            text: this.__('interface.accept'),
            handler: () => {
              this.actionClientInviteAccept(item)
            }
          },
          {
            text: this.__('interface.decline'),
            role: 'destructive',
            handler: () => {
              this.actionClientInviteDecline(item)
            }
          },
          {
            text: this.__('interface.cancel'),
            role: 'cancel'
          }
        ]
      });
      return actionSheet.present();
    }
  },
  async ionViewDidEnter() {
    const loading = await loadingController.create({
      message: this.__('interface.loading'),
    })
    await loading.present()

    this.loadData().finally(() => {
      loading.dismiss()
    })
  }
}
</script>